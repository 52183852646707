<template>
  <form @submit="onSubmit" class="container container--xxxxs authenticationForm">

    <BaseAlertList
        :alerts="formAlerts"
        class="mb-4"
    />

    <!-- Email -->
    <FormGroup
        :label="translation('global_input-email-label')"
        :required="true"
        :type="'email'"
        :placeholder="translation('global_input-email-placeholder')"
        :uuid="`email`"
        :error="emailError"
        v-model="email"
    >
      <template v-slot:icon>
        <img
            src="../assets/images/icons/envelope.svg"
            alt=""
            class="icon"
            width="23"
            height="18"
            loading="lazy"
        >
      </template>
    </FormGroup>

    <!-- Password -->
    <FormGroup
        :label="translation('global_input-password-label')"
        :required="true"
        :type="isPasswordRevealed ? 'text' : 'password'"
        :placeholder="translation('global_input-password-placeholder')"
        :uuid="`password`"
        :error="passwordError"
        :showErrorIcon="false"
        class="mb-0"
        v-model="password"
    >
      <template v-slot:icon>
        <img
            src="../assets/images/icons/lock.svg"
            alt=""
            class="icon"
            width="19"
            height="23"
            loading="lazy"
        >
      </template>
      <template v-slot:button>
        <button
            type="button"
            class="passwordReveal"
            @click="handlePasswordReveal"
        >
          <img
              v-if="isPasswordRevealed"
              src="../assets/images/icons/eye-closed.svg"
              alt=""
              class="icon"
              width="24"
              height="20"
              loading="lazy"
          >
          <img
              v-if="!isPasswordRevealed"
              src="../assets/images/icons/eye.svg"
              alt=""
              class="icon"
              width="24"
              height="14"
              loading="lazy"
          >
        </button>
      </template>
    </FormGroup>

    <!-- Reset -->
    <div class="mt-1">
      <a href="/reset" class="formLink">{{ translation('forgot_password_hint-link') }}</a>
    </div>

    <!-- Button -->
    <BaseButton
        type="submit"
        class="mt-4"
        :full-width="true"
        :isDisabled="isSubmitting"
    >
      {{ translation('login_form-submit_button') }}
    </BaseButton>
  </form>
</template>

<script>
import BaseAlertList from "@/components/BaseAlertList";
import BaseButton from "@/components/BaseButton";
import FormGroup from "@/components/FormGroup";
import {passwordRevealer} from "@/helpers/passwordRevealer";
import {authenticationService} from "@/services/authentication";
import {codeBooksHelpers} from "@/helpers/codeBooks.helper";
import {UnauthorizedError} from "@/services/errors";
import {useField, useForm} from 'vee-validate';
import {ref} from "vue";
import * as yup from 'yup';

export default {
  components: {
    BaseButton,
    FormGroup,
    BaseAlertList
  },
  setup() {
    // import password revealer
    const {isPasswordRevealed, handlePasswordReveal} = passwordRevealer()

    // form level alert variable
    let formAlerts = ref(null);

    // import translations
    const {translation} = codeBooksHelpers()

    // validator config
    const validationSchema = yup.object({
      email: yup
          .string()
          .required(() => translation('global_input-email-error'))
          .email(() => translation('global_validation-email_format')),
      password: yup
          .string()
          .required(() => translation('global_input-password-error')),
    })

    // Creates a vee-validate form context
    const {handleSubmit, isSubmitting} = useForm({
      validationSchema
    });

    // create a form fields with its validation state
    const {value: email, errorMessage: emailError} = useField('email')
    const {value: password, errorMessage: passwordError, resetField} = useField('password')

    // submit handler
    const onSubmit = handleSubmit(async values => {

      try {
        // Send data to the API
        await authenticationService.login(values.email, values.password)

        // redirect
        location.href = '/courses'
      } catch (err) {
        if (err instanceof UnauthorizedError) {
          formAlerts.value = [{'message': translation('login_form-alert-invalid_credentials'), type: "danger"}];
        } else {
          formAlerts.value = [{'message': translation('global_alert-general'), type: "danger"}];
        }
      } finally {
        // reset filed
        resetField({
          value: ''
        })
      }
    });

    return {
      email,
      emailError,
      password,
      passwordError,
      onSubmit,
      isSubmitting,
      formAlerts,
      translation,
      handlePasswordReveal,
      isPasswordRevealed
    };
  }
}
</script>