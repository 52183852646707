<template>
  <BaseLayout :breadcrumb="breadcrumb">
    <CenteredTitle>{{ translation('login_page-title') }}</CenteredTitle>
    <a href="/api/authorize">
      <div class="google-btn">
      <div class="google-icon-wrapper">
        <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"/>
      </div>
      <p class="btn-text"><b>Přihlášení Microsoft účtem</b></p>
    </div>
    </a>

  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import CenteredTitle from "@/components/CenteredTitle";
import LoginForm from '@/components/LoginForm.vue'
import SignHint from "@/components/SignHint";
import codeBooksMixin from "@/mixins/codeBooksMixin";

export default {
  computed: {
    breadcrumb() {
      return [{
        title: this.translation('login_page-title'),
        url: '/login',
      }];
    }
  },
  mixins: [codeBooksMixin],
  components: {
    SignHint,
    CenteredTitle,
    BaseLayout,
    LoginForm,
  },
}
</script>
